<template>
  <div>
    <section>
      <div class="max-w-4xl mx-auto mt-6">
        <div class="text-center mb-12 text-2xl text-body-1 font-semibold">
          Příprava domácnosti na focení
        </div>
        <div
          :class="[
            'mx-8 p-6 rounded-md ' +
              (homeStagingOrdered ? 'border-2 border-blue-700' : ''),
            stagingDBTerm !== null
              ? 'bg-green-200 bg-opacity-25'
              : 'bg-body-1 bg-opacity-5',
          ]"
        >
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div>
              <h2 class="text-17 text-body-4 font-bold">
                Příprava domácnosti na focení
              </h2>
            </div>
            <div class="text-left md:text-right">
              <h2 class="text-17 text-body-4 font-bold">
                <p class="text-body-1 text-xl" v-if="stagingComparator">✔</p>
                <p v-if="!stagingComparator">{{ homeStaging.price }} Kč</p>
              </h2>
            </div>
          </div>
          <div class="mt-6">
            <p class="text-17 text-body-4">
              Profesionální designeři interiérů naaranžují vaši nemovitost tak,
              aby vybízel k nákupu. Vizuálně čistě připravená nemovitost má
              mnohem větší potenciál na prodej za vyšší cenu.
            </p>
          </div>
          <div class="mt-6">
            <div v-if="allDataFetched">
              <div class="inline-flex">
                <div v-if="!stagingComparator">
                  <ButtonSpinnerStripe
                    :stripe-price-id="homeStaging.stripePriceId"
                  />
                </div>
                <div v-if="stagingComparator">
                  <button
                    v-if="stagingDBTerm === null"
                    class="font-semibold px-4 mr-4 bg-white h-12 text-body-1 rounded-md focus:outline-none border-2 border-body-1 mt-8 md:mt-0"
                    @click="$router.push('/vyber-terminu-staging')"
                  >
                    Vybrat termín
                  </button>
                  <button
                    v-if="stagingDBTerm !== null"
                    class="modal-open w-48 mr-8 px-2 h-12 text-body-1 font-bold rounded-md focus:outline-none cursor-auto"
                  >
                    Termín: {{ stagingDBTerm.Date | moment("DD.MM.YYYY") }}
                  </button>
                  <input
                    class="hidden hover:opacity-50 duration-300"
                    id="staging-btn"
                    @input="uploadUserStagingImagesHandler($event)"
                    style="color: blue"
                    type="file"
                    hidden
                    multiple
                    accept="image/png,image/gif,image/jpeg"
                  />
                  <label
                    class="hidden hover:opacity-80 px-4 bg-white h-12 text-body-1 rounded-md focus:outline-none border-2 border-body-1 mt-8 md:mt-0 p-2 cursor-pointer"
                    for="staging-btn"
                    >Vybrat fotografie pro inspiraci
                  </label>
                </div>
              </div>
              <div>
                <div v-if="stagingPhotos.length" class="mt-6">
                  <div class="flex justify-between">
                    <h2 class="text-17 text-body-4 font-bold mb-4">
                      Vaše fotografie pro inspiraci
                    </h2>
                  </div>
                  <div
                    class="flex space-x-2 overflow-x-scroll mb-4 rounded-md bg-transparent"
                  >
                    <div v-for="image in stagingPhotos" :key="image.id">
                      <div class="flex-1 rounded-lg pb-1 w-64">
                        <a target="_blank" :href="image.images">
                          <img
                            class="mr-2 border-2 border-blue-700 hover:opacity-60"
                            :src="image.images"
                          />
                        </a>
                        <span
                          style="position: relative; z-index: 2; bottom: 32px"
                          @click="deleteImage(image)"
                          ><Delete
                        /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-2 pb-2.5" v-if="!allDataFetched">
              <img src="../../../public/images/dataLoading.gif" />
            </div>
          </div>
        </div>
        <div
          :class="[
            'mx-8 p-6 rounded-md mt-6 ' +
              (professionalFotoOrdered ? 'border-2 border-blue-700' : ''),
            ,
            photoDBTerm !== null
              ? 'bg-green-200 bg-opacity-25'
              : 'bg-body-1 bg-opacity-5',
          ]"
        >
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div>
              <h2 class="text-17 text-body-4 font-bold">
                Profesionální fotografie
              </h2>
            </div>
            <div class="text-left md:text-right">
              <h2 class="text-17 text-body-4 font-bold">
                <p class="text-body-1 text-xl" v-if="photoComparator">✔</p>
                <p v-if="!photoComparator">{{ professionalFoto.price }} Kč</p>
              </h2>
            </div>
          </div>
          <div class="mt-6">
            <p class="text-17 text-body-4">
              Zkušení fotografové připraví autentické snímky, které zájemcům o
              nákup nemovitosti pomohou vytvořit lepší představu o interiéru a
              motivují je ke sjednání prohlídky. Čím přívětivěji vaše nemovitost
              bude vypadat, tím větší vzbudí zájem.
            </p>
          </div>
          <div class="mt-6">
            <div v-if="allDataFetched">
              <div class="inline-flex">
                <div v-if="!photoComparator">
                  <ButtonSpinnerStripe
                    :stripe-price-id="professionalFoto.stripePriceId"
                  />
                </div>
                <div v-if="photoComparator">
                  <button
                    v-if="photoDBTerm === null"
                    class="font-semibold px-4 mr-4 bg-white h-12 text-body-1 rounded-md focus:outline-none border-2 border-body-1 mt-8 md:mt-0"
                    @click="$router.push('/vyber-terminu-foceni')"
                  >
                    Vybrat termín
                  </button>
                  <button
                    v-if="photoDBTerm !== null"
                    class="modal-open w-48 mr-8 px-2 h-12 text-body-1 font-bold rounded-md focus:outline-none cursor-auto"
                  >
                    Termín: {{ photoDBTerm.Date | moment("DD.MM.YYYY") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="pt-2 pb-2.5" v-if="!allDataFetched">
              <img src="../../../public/images/dataLoading.gif" />
            </div>
          </div>
        </div>

        <div
          :class="[
            'mx-8 p-6 rounded-md mt-6 ' +
              (professionalVideoOrdered ? 'border-2 border-blue-700' : ''),
            ,
            videoDBTerm !== null
              ? 'bg-green-200 bg-opacity-25'
              : 'bg-body-1 bg-opacity-5',
          ]"
        >
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div>
              <h2 class="text-17 text-body-4 font-bold">Profesionální video</h2>
            </div>
            <div class="text-left md:text-right">
              <h2 class="text-17 text-body-4 font-bold">
                <p class="text-body-1 text-xl" v-if="videoComparator">✔</p>
                <p v-if="!videoComparator">{{ professionalVideo.price }} Kč</p>
              </h2>
            </div>
          </div>
          <div class="mt-6">
            <p class="text-17 text-body-4">
              Profesionálně natočené a sestříhané video vytvoří potřebný WOW
              efekt a v mnoha ohledech nahradí i osobní prohlídku nemovitosti.
              Díky tomu zredukujete množství prohlídek pouze na zájemce, kteří
              mají o nemovitost skutečný zájem. A méně prohlídek s vážnými
              zájemci znamená rychlejší a výhodnější prodej.
            </p>
          </div>
          <div class="mt-6">
            <div v-if="allDataFetched">
              <div class="inline-flex">
                <div v-if="!videoComparator">
                  <ButtonSpinnerStripe
                    :stripe-price-id="professionalVideo.stripePriceId"
                  />
                </div>
                <div v-if="videoComparator">
                  <button
                    v-if="videoDBTerm === null"
                    class="font-semibold px-4 mr-4 bg-white h-12 text-body-1 rounded-md focus:outline-none border-2 border-body-1 mt-8 md:mt-0"
                    @click="$router.push('/vyber-terminu-video')"
                  >
                    Vybrat termín
                  </button>
                  <button
                    v-if="videoDBTerm !== null"
                    class="modal-open w-48 mr-8 px-2 h-12 text-body-1 font-bold rounded-md focus:outline-none cursor-auto"
                  >
                    Termín: {{ videoDBTerm.Date | moment("DD.MM.YYYY") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="pt-2 pb-2.5" v-if="!allDataFetched">
              <img src="../../../public/images/dataLoading.gif" />
            </div>
          </div>
        </div>

        <div
          :class="[
            'mx-8 p-6 rounded-md mt-6 ' +
              (treeDModelOrdered ? 'border-2 border-blue-700' : ''),
            ,
            modelDBTerm !== null
              ? 'bg-green-200 bg-opacity-25'
              : 'bg-body-1 bg-opacity-5',
          ]"
        >
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div>
              <h2 class="text-17 text-body-4 font-bold">3D Model</h2>
            </div>
            <div class="text-left md:text-right">
              <h2 class="text-17 text-body-4 font-bold">
                <p class="text-body-1 text-xl" v-if="modelComparator">✔</p>
                <p v-if="!modelComparator">{{ treeDModel.price }} Kč</p>
              </h2>
            </div>
          </div>
          <div class="mt-6">
            <p class="text-17 text-body-4">
              3D model neboli virtuální prohlídka zájemcům o nemovitost umožní
              pohyb po místnostech, jako by v nich skutečně byli. To znamená, že
              svou nemovitost dáváte k dispozici 24/7, aniž byste museli cokoliv
              dělat. Stejně jako v případě videa tak zredukujete množství
              prohlídek pouze na vážné zájemce.
            </p>
          </div>
          <div class="mt-6">
            <div v-if="allDataFetched">
              <div class="inline-flex">
                <div v-if="!modelComparator">
                  <ButtonSpinnerStripe
                    :stripe-price-id="treeDModel.stripePriceId"
                  />
                </div>
                <div v-if="modelComparator">
                  <button
                    v-if="modelDBTerm === null"
                    class="font-semibold px-4 mr-4 bg-white h-12 text-body-1 rounded-md focus:outline-none border-2 border-body-1 mt-8 md:mt-0"
                    @click="$router.push('/vyber-terminu-model')"
                  >
                    Vybrat termín
                  </button>
                  <button
                    v-if="modelDBTerm !== null"
                    class="modal-open w-48 mr-8 px-2 h-12 text-body-1 font-bold rounded-md focus:outline-none cursor-auto"
                  >
                    Termín: {{ modelDBTerm.Date | moment("DD.MM.YYYY") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="pt-2 pb-2.5" v-if="!allDataFetched">
              <img src="../../../public/images/dataLoading.gif" />
            </div>
          </div>
        </div>

        <div
          :class="
            photosFromDb.length
              ? 'bg-green-200 bg-opacity-25'
              : 'bg-body-1 bg-opacity-5'
          "
          class="mx-8 mt-6 p-6 rounded-md border-blue-700"
        >
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div>
              <h2 class="text-17 text-body-4 font-bold">
                Mám vlastní fotografie
              </h2>
            </div>
            <div class="text-left md:text-right">
              <h2 class="text-17 text-body-4 font-bold">Zdarma</h2>
            </div>
          </div>
          <div class="mt-6">
            <p class="text-17 text-body-4 pb-2">
              V případě, že nechcete využít profesionálních služeb, nabízených
              aplikací MakléřeNechci, nahrajte své fotografie zde.
            </p>
            <div class="flex">
              <div><Info /></div>
              <span class="text-gray-500 text-md text-body-1 select-none pl-2"
                >Fotografie musí mít orientaci na šířku.
              </span>
            </div>

            <div v-if="badOrientation">
              <span
                style="line-height: 24px"
                class="text-gray-500 text-md text-red-600 select-none pt-2 font-semibold"
                >Některé fotografie byly odstraněny kvůli orientaci na výšku.
              </span>
            </div>
          </div>
          <div class="mt-6">
            <input
              class="hover:opacity-50 duration-300"
              id="actual-btn"
              @input="uploadUserImagesHandler($event)"
              style="color: blue"
              type="file"
              hidden
              multiple
              accept="image/png,image/gif,image/jpeg"
            />
            <label
              class="hover:opacity-80 px-4 bg-white h-12 text-body-1 rounded-md focus:outline-none border-2 border-body-1 mt-8 md:mt-0 p-2 cursor-pointer"
              for="actual-btn"
              >Vybrat fotografie
            </label>
          </div>

          <div v-if="photoUpload" class="flex py-4">
            <span><Warning /></span>
            <div style="line-height: 64px" class="text-red-600 font-bold pl-2">
              Nahrávám fotografie, prosím neodcházejte!
            </div>
          </div>

          <div v-if="photosFromDb.length" class="mt-6">
            <div class="flex justify-between">
              <h2 class="text-17 text-body-4 font-bold mb-4">
                Vaše fotogalerie
              </h2>
            </div>
            <div
              class="flex space-x-2 overflow-x-scroll mb-4 rounded-md bg-transparent"
            >
              <div v-for="image in photosFromDb" :key="image.id">
                <div
                  v-if="image.type !== 'staging'"
                  class="flex-1 rounded-lg pb-1 w-64"
                >
                  <a target="_blank" :href="image.images">
                    <img
                      class="mr-2 border-2 border-blue-700 hover:opacity-60"
                      :src="image.images"
                    />
                  </a>
                  <span
                    style="position: relative; z-index: 2; bottom: 32px"
                    @click="deleteImage(image)"
                    ><Delete
                  /></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          :class="
            videosFromDb.length
              ? 'bg-green-200 bg-opacity-25'
              : 'bg-body-1 bg-opacity-5'
          "
          class="mx-8 mt-6 p-6 rounded-md border-blue-700"
        >
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div>
              <h2 class="text-17 text-body-4 font-bold">Mám vlastní video</h2>
            </div>
            <div class="text-left md:text-right">
              <h2 class="text-17 text-body-4 font-bold">Zdarma</h2>
            </div>
          </div>
          <div class="mt-6">
            <p class="text-17 text-body-4">
              V případě, že nechcete využít profesionálních služeb, nabízených
              aplikací MakléřeNechci, nahrajte své video zde.
            </p>
          </div>
          <div
            v-if="
              nowYouCanAddVideo && !videosFromDb.length && !userVideos.length
            "
            class="mt-6"
          >
            <input
              class="hover:opacity-50 duration-300"
              id="actual-btn-video"
              @input="uploadUserVideosHandler($event)"
              style="color: blue"
              type="file"
              hidden
              multiple
              accept="video/mp4,video/x-m4v,video/*"
            />
            <label
              class="hover:opacity-80 px-4 bg-white h-12 text-body-1 rounded-md focus:outline-none border-2 border-body-1 mt-8 md:mt-0 p-2 cursor-pointer"
              for="actual-btn-video"
              >Vybrat video
            </label>
          </div>
          <div v-if="videoUpload" class="flex py-4">
            <span><Warning /></span>
            <div style="line-height: 64px" class="text-red-600 font-bold pl-2">
              Nahrávám video, prosím neodcházejte!
            </div>
          </div>
          <!-- <div v-if="userVideos.length" class="mt-6">
            <h2 class="text-17 text-body-4 font-bold">Právě nahráno</h2>
            <div class="grid grid-cols-6 gap-1 mt-2">
              <div v-for="vid in userVideos" :key="vid.id">
                <Video />
              </div>
            </div>
          </div> -->
          <div v-if="videosFromDb.length" class="mt-6">
            <div class="flex justify-between">
              <h2 class="text-17 text-body-4 font-bold">Vaše video</h2>
            </div>
            <div class="grid grid-cols-6 gap-1 mt-2">
              <div v-for="video in videosFromDb" :key="video.id">
                <span
                  style="position: absolute; z-index: 3"
                  @click="deleteVideo(video)"
                  ><Delete
                /></span>
                <a target="_blank" :href="video.videos">
                  <Video />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="mx-8 bg-green-200 bg-opacity-25 p-6 rounded-md mt-6">
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div>
              <h2 class="text-17 text-body-4 font-bold">Webová stránka</h2>
            </div>
            <div class="text-left md:text-right">
              <h2 class="text-17 text-body-4 font-bold">Zdarma</h2>
            </div>
          </div>
          <div class="mt-6">
            <p class="text-17 text-body-4">
              Prohlédněte si webovou stránku prezentující vaši nemovitost.
            </p>
            <div>
              <router-link :to="encodedUri" target="_blank">
                <button
                  class="mt-4 modal-open hover:opacity-80 px-4 bg-body-1 h-12 text-white rounded-md focus:outline-none border-2 border-body-1"
                >
                  Zobrazit Vaši webovou stránku
                </button>
              </router-link>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 mt-12 mx-8 mb-24">
          <div class="hover:opacity-50 duration-300">
            <button
              class="px-8 text-body-3 border border-body-3 font-bold text-15 focus:outline-none rounded-md h-14"
              @click="$router.go(-1)"
            >
              Krok zpět
            </button>
          </div>
          <div
            class="text-left md:text-right hover:opacity-80 mt-8 md:mt-0 duration-300"
          >
            <button
              class="px-8 font-bold text-15 bg-body-3 text-white focus:outline-none rounded-md h-14"
              @click="save()"
            >
              Pokračovat
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getUserTerms,
  getStripe,
  getImages,
} from "../../services/rest/services";
import ButtonSpinnerStripe from "@/components/buttons/ButtonSpinnerStripe";
import Delete from "@/components/svg/Delete.vue";
import Warning from "../../components/svg/Warning.vue";
import axios from "axios";
import Video from "@/components/svg/Video.vue";
import imageCompression from "browser-image-compression";
import { uploadToDigitalOcean } from "../../services/rest/services";
import Info from "../../components/svg/Info.vue";
import { IS_BETA, IS_LOCALHOST } from '../../globalConstants';

export default {
  components: {
    ButtonSpinnerStripe,
    Delete,
    Video,
    Warning,
    Info,
  },
  computed: {
    ...mapState("main", ["currentProperty"]),
    ...mapState("user", ["user"]),
  },
  async mounted() {
    this.fetchStagingTerm();
    this.fetchPhotoTerm();
    this.fetchVideoTerm();
    this.fetchModelTerm();

    if (this.currentProperty.id) {
      let normalizeName = this.currentProperty.name
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "");
      this.encodedUri = `inzerce/${normalizeName}/${this.currentProperty.id}`;
    }
    // Stripe getData by currentPropertyId MODULE
    const receipts = await getStripe(this.currentProperty.id);
    if (receipts) {
      let receiptDescriptionArray = [];
      receipts.forEach((receipt) =>
        receiptDescriptionArray.push(receipt.description)
      );
      this.paidServiceHandler(receiptDescriptionArray);
      this.allDataFetched = true;
    }
    // ^^^
    this.photosFromDb = await getImages(this.currentProperty.id);
    //Fetch video
    await axios
      .get(`${process.env.VUE_APP_STRAPI_API_URL}/user-videos`, {
        headers: {
          Authorization: `Bearer ${this.user.jwt}`,
        },
      })
      .then((res) => {
        res.data.map((video) => {
          if (video.property_id === this.$store.state.main.currentProperty.id) {
            this.videosFromDb.push(video);
          }
        });
      })
      .catch((err) => {
        return err;
      });
    this.nowYouCanAddVideo = true;

    await axios
      .get(`${process.env.VUE_APP_STRAPI_API_URL}/user-images`, {
        headers: {
          Authorization: `Bearer ${this.user.jwt}`,
        },
      })
      .then((res) => {
        res.data.map((image) => {
          if (
            image.property_id === this.$store.state.main.currentProperty.id &&
            image.type === "staging"
          ) {
            this.stagingPhotos.push(image);
          }
        });
      })
      .catch((err) => {
        return err;
      });
  },
  data() {
    return {
      encodedUri: "",
      photoUpload: false,
      photoIdsForDelete: [],
      badOrientation: false,
      homeStaging: {
        stripePriceId: IS_BETA || IS_LOCALHOST
          ? "price_1IqHTcI4HEbZGFYDomdJNFTK"
          : "price_1LhURfI4HEbZGFYDgMM7veck",
        price: 2500,
      },
      professionalFoto: {
        stripePriceId: IS_BETA || IS_LOCALHOST
          ? "price_1IqHUCI4HEbZGFYDyOYG7p5H"
          : "price_1LhUSaI4HEbZGFYD3eQlZEr7",
        price: 3000,
      },
      professionalVideo: {
        stripePriceId: IS_BETA || IS_LOCALHOST
          ? "price_1IqHUhI4HEbZGFYDzDcIN2TT"
          : "price_1LhUTCI4HEbZGFYDzYhdk71n",
        price: 4500,
      },
      treeDModel: {
        stripePriceId: IS_BETA || IS_LOCALHOST
          ? "price_1IqHVGI4HEbZGFYDvYeKbkWI"
          : "price_1LhUXdI4HEbZGFYDokScP8Hq",
        price: 1500,
      },
      myOwnFotos: {},
      webPage: {},

      homeStagingOrdered: false,
      professionalFotoOrdered: false,
      professionalVideoOrdered: false,
      treeDModelOrdered: false,

      //Photos from db
      photosFromDb: [],

      //Videos from db
      videosFromDb: [],
      videoUpload: false,
      //Terms states configuration
      stagingTerms: [],
      photoTerms: [],
      videoTerms: [],
      modelTerms: [],
      stagingTerm: "",
      photoTerm: "",
      videoTerm: "",
      modelTerm: "",
      type: null,

      //check video
      nowYouCanAddVideo: false,

      //reactive-term
      reactiveStaging: "",
      reactivePhoto: "",
      reactiveVideo: "",
      reactiveModel: "",

      //paidServices
      stagingComparator: false,
      photoComparator: false,
      videoComparator: false,
      modelComparator: false,

      //userTerms from DB
      allUserTerms: null,
      stagingDBTerm: null,
      photoDBTerm: null,
      videoDBTerm: null,
      modelDBTerm: null,

      //allDataFetched ?
      allDataFetched: false,

      //uploadManager
      openUploadManager: false,
      files: [],
      userImages: [],
      userVideos: [],
      stagingPhotos: [],

      images: [],
    };
  },

  methods: {
    async deleteImage(e) {
      await axios.post(
        `${process.env.VUE_APP_STRAPI_API_URL}/user-images/delete/${e.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
          },
        }
      );

      await axios
        .get(`${process.env.VUE_APP_STRAPI_API_URL}/user-images`, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
          },
        })
        .then((res) => {
          res.data.map((image) => {
            if (
              image.property_id === this.$store.state.main.currentProperty.id
            ) {
              if (image.type === "staging") {
                this.stagingPhotos = [];
                this.stagingPhotos.push(image);
              } else {
                this.photosFromDb.push(image);
              }
            }
          });
        })
        .catch((err) => {
          return err;
        });
      this.photosFromDb = await getImages(this.currentProperty.id);
      this.userImages = this.photosFromDb.map((i) => i.images);
    },

    async deleteVideo(e) {
      await axios.delete(
        `${process.env.VUE_APP_STRAPI_API_URL}/user-videos/${e.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
          },
        }
      );
      this.videosFromDb = [];
      this.userVideos = [];
      await axios
        .get(`${process.env.VUE_APP_STRAPI_API_URL}/user-videos`, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
          },
        })
        .then((res) => {
          res.data.map((video) => {
            if (
              video.property_id === this.$store.state.main.currentProperty.id
            ) {
              this.videosFromDb.push(video);
            }
          });
        })
        .catch((err) => {
          return err;
        });
    },

    async uploadUserImagesHandler(e) {
      this.photoUpload = true;
      this.badOrientation = false;
      const fullImages = e.target.files;
      let compressedFile;
      // if any of that images is larger then 5 MB, then is reduced
      for (const image of fullImages) {
        const options = {
          maxSizeMB: 5,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        compressedFile = await imageCompression(image, options);
        this.files.push(compressedFile);
      }

      let formData = new FormData();
      //Alway multiple upload of these files
      Array.from(this.files).forEach((image) => {
        formData.append("files", image);
      });

      console.log("form data => ", formData);
      //Request to upload on DO provider
      await uploadToDigitalOcean(formData)
        .then(async (res) => {
          this.userImages = res.map((i) => i.url);
          await Promise.all(
            this.userImages.map((imageSrc) => {
              return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = imageSrc;
                img.onload = resolve;
                img.onerror = reject;
                this.images.push(img);
              }).then(() => {
                for (let image of this.images) {
                  if (image.height > image.width) {
                    this.photoIdsForDelete.push(
                      image.currentSrc.split(".com/")[1]
                    );
                  }
                }
              });
            })
          );
        })
        .catch((err) => {
          return err;
        });

      this.photosFromDb = await getImages(this.currentProperty.id);

      //Save url of images into database with user_id
      this.userImages.forEach(async (imgUrl) => {
        await axios.post(
          `${process.env.VUE_APP_STRAPI_API_URL}/user-images`,
          {
            user_id: this.user.id,
            images: imgUrl,
            property_id: this.currentProperty.id,
          },
          {
            headers: {
              Authorization: `Bearer ${this.user.jwt}`,
            },
          }
        );
      });
      this.userImages = [];
      setTimeout(async () => {
        this.photosFromDb = await getImages(this.currentProperty.id);
      }, 1000);

      setTimeout(async () => {
        this.photosFromDb.forEach((photo) => {
          this.photoIdsForDelete.forEach((id) => {
            if (photo.images.split(".com/")[1] === id) {
              this.deleteImage(photo);
              this.badOrientation = true;
            }
          });
        });
      }, 2000);

      formData = [];
      compressedFile = [];
      this.files = [];
      this.photoUpload = false;
    },

    async uploadUserStagingImagesHandler(e) {
      const fullStagingImages = e.target.files;
      let compressedFile;
      // if any of that images is larger then 5 MB, then is reduced
      for (const image of fullStagingImages) {
        const options = {
          maxSizeMB: 5,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        compressedFile = await imageCompression(image, options);
        this.files.push(compressedFile);
      }

      let formData = new FormData();
      //Alway multiple upload of these files
      Array.from(this.files).forEach((image) => {
        formData.append("files", image);
      });

      await uploadToDigitalOcean(formData)
        .then((res) => {
          this.stagingPhotos = res.map((i) => i.url);
        })
        .catch((err) => {
          return err;
        });

      //Save url of images into database with user_id
      const staging = this.stagingPhotos;
      staging.forEach(async (i) => {
        await axios
          .post(
            `${process.env.VUE_APP_STRAPI_API_URL}/user-images`,
            {
              user_id: this.user.id,
              images: i,
              property_id: this.currentProperty.id,
              type: "staging",
            },
            {
              headers: {
                Authorization: `Bearer ${this.user.jwt}`,
              },
            }
          )
          .then((res) => {
            return res;
          });
      });

      //Fetch photos
      setTimeout(async () => {
        await axios
          .get(`${process.env.VUE_APP_STRAPI_API_URL}/user-images`, {
            headers: {
              Authorization: `Bearer ${this.user.jwt}`,
            },
          })
          .then((res) => {
            res.data.map((image) => {
              if (
                image.property_id ===
                  this.$store.state.main.currentProperty.id &&
                image.type === "staging"
              ) {
                this.stagingPhotos = [];
                this.stagingPhotos.push(image);
              }
            });
          })
          .catch((err) => {
            return err;
          });
      }, 1000);
    },

    async uploadUserVideosHandler(e) {
      this.videoUpload = true;
      this.files = e.target.files;
      let formData = new FormData();
      //Alway multiple upload of these files
      Array.from(this.files).forEach((video) => {
        formData.append("files", video);
      });
      //Request to upload on DO provider
      await uploadToDigitalOcean(formData)
        .then((res) => {
          this.userVideos = res.map((i) => i.url);
        })
        .catch((err) => {
          return err;
        });
      //Save url of images into database with user_id
      const videos = this.userVideos;
      videos.forEach((video) => {
        axios
          .post(
            `${process.env.VUE_APP_STRAPI_API_URL}/user-videos`,
            {
              user_id: this.user.id,
              videos: video,
              property_id: this.currentProperty.id,
            },
            {
              headers: {
                Authorization: `Bearer ${this.user.jwt}`,
              },
            }
          )
          .catch((err) => {
            return err;
          });
      });
      this.videoUpload = false;
      //Fetch videos
      this.videosFromDb = [];
      setTimeout(async () => {
        await axios
          .get(`${process.env.VUE_APP_STRAPI_API_URL}/user-videos`, {
            headers: {
              Authorization: `Bearer ${this.user.jwt}`,
            },
          })
          .then((res) => {
            res.data.map((video) => {
              if (
                video.property_id === this.$store.state.main.currentProperty.id
              ) {
                this.videosFromDb.push(video);
              }
            });
          })
          .catch((err) => {
            return err;
          });
      }, 1000);
      this.files = [];
    },

    async userTermsHandler() {
      const userPaymentResults = await axios
        .get(
          `${process.env.VUE_APP_STRAPI_API_URL}/user-terms/${this.currentProperty.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.user.jwt}`,
            },
          }
        )
        .then((this.allDataFetched = true));
      this.allUserTerms = userPaymentResults.data;

      const allServiceTypes = async () => {
        this.stagingDBTerm =
          this.allUserTerms.find((i) => i.Type === "staging") || null;
        this.photoDBTerm =
          this.allUserTerms.find((i) => i.Type === "photo") || null;
        this.videoDBTerm =
          this.allUserTerms.find((i) => i.Type === "video") || null;
        this.modelDBTerm =
          this.allUserTerms.find((i) => i.Type === "model") || null;
      };
      await allServiceTypes();
    },

    async paidServiceHandler(productName) {
      if (productName) {
        if (productName.includes("1x Home staging (domácí scéna)")) {
          this.stagingComparator = true;
        } else this.stagingComparator = false;
        if (productName.includes("1x Profesionální fotografie")) {
          this.photoComparator = true;
        } else this.photoComparator = false;
        if (productName.includes("1x Profesionální video")) {
          this.videoComparator = true;
        } else this.videoComparator = false;
        if (productName.includes("1x 3D Model")) {
          this.modelComparator = true;
        } else this.modelComparator = false;
        await this.userTermsHandler();
      }
      return (this.allDataFetched = true);
    },

    async fetchStagingTerm() {
      this.type = "staging";
      const result = await getUserTerms(this.currentProperty.id, this.type);
      if (result.data) {
        this.stagingTerms = result.data.userTerms;
        if (!this.stagingTerms.length == 0) {
          this.stagingTerm = this.stagingTerms[
            this.stagingTerms.length - 1
          ].Date;
        } else return;
      }
      return;
    },

    async fetchPhotoTerm() {
      this.type = "photo";
      const result = await getUserTerms(this.currentProperty.id, this.type);
      if (result.data) {
        this.photoTerms = result.data.userTerms;
        if (!this.photoTerms.length == 0) {
          this.photoTerm = this.photoTerms[this.photoTerms.length - 1].Date;
        } else return;
      }
      return;
    },

    async fetchVideoTerm() {
      this.type = "video";
      const result = await getUserTerms(this.currentProperty.id, this.type);
      if (result.data) {
        this.videoTerms = result.data.userTerms;
        if (!this.videoTerms.length == 0) {
          this.videoTerm = this.videoTerms[this.videoTerms.length - 1].Date;
        } else return;
      }
      return;
    },

    async fetchModelTerm() {
      this.type = "model";
      const result = await getUserTerms(this.currentProperty.id, this.type);
      if (result.data) {
        this.modelTerms = result.data.userTerms;
        if (!this.modelTerms.length == 0) {
          this.modelTerm = this.modelTerms[this.modelTerms.length - 1].Date;
        } else return;
      }
      return;
    },

    save() {
      try {
        this.$router.push("/inzerce");
      } catch (error) {
        this.$toast(this.$t("ErrorMessages.ErrorWhileSaving"), {
          className: ["et-alert"],
        });
      }
    },

    orderHomeStaging() {
      this.homeStagingOrdered = true;
    },

    removeHomeStaging() {
      this.homeStagingOrdered = false;
    },

    orderProfessionalFoto() {
      this.professionalFotoOrdered = true;
      this.$router.push("/vyber-terminu-foceni");
    },

    removeProfessionalFoto() {
      this.professionalFotoOrdered = false;
    },

    orderProfessionalVideo() {
      this.professionalVideoOrdered = true;
    },

    removeProfessionalVideo() {
      this.professionalVideoOrdered = false;
    },

    remove3DModel() {
      this.treeDModelOrdered = false;
    },

    order3DModel() {
      this.treeDModelOrdered = true;
    },
  },
};
</script>

<style scoped>
.btn-remove {
  position: relative;
  bottom: 35px;
  left: 40px;
  float: right;
}
</style>
